$primary: #061c01 !default;
$secondary: rgb(254, 221, 0) !default;

@import "../../node_modules/bootstrap/scss/bootstrap";
@import "font";

body {
  font-family: "prompt";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.country-generator {
  //box-shadow: rgb(13 12 34 / 10%) -6px 0px 30px;
  box-shadow: #ececec -6px 0px 30px;
  padding: 40px;
  margin: 15px;
  .header-wrapper {
    margin-bottom: 50px;
    .title {
      margin: 30px 0;
      font-family: "prompt";
      font-weight: bold;
      font-size: 27px;
      padding-bottom: 15px;
    }
  }
  .generated {
    background: #fbfbfb;
    margin: 5px 0;
    padding: 15px 0px;
    border-radius: 5px;
    font-size: 15px;
    cursor: pointer;
    transform: scale(1);
    transition: all 400ms;
    &:hover {
      background: #f3f3f3;
      transform: scale(1.05);
    }
  }
  .attribute-name {
    font-family: prompt-light;
    font-size: 12px;
    margin-bottom: 9px;
  }
  .react-date-picker__wrapper {
    padding: 7px;
    border-color: #ded8d8;
    border-radius: 4px;
    .react-date-picker__inputGroup__input {
      color: #4e4b4b;
    }
  }
  .result-list {
    margin: 45px 0;
  }

  .btn-random{
    margin: 20px 0;
    padding: 10px 15px;
    border-radius: 5px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    color: white;
    &.light {
      background: $secondary;
      border-color: $secondary;
      color: black;
      &:focus {
        box-shadow: 0 0 0 0.2rem rgba(254, 221, 0, 50%);
      }
    }
  }
}

input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #efefef;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: $primary;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

hr{

  border:         none;
  border-left:    1px solid hsl(0, 0%, 89%);
  height:         100%;
  width:          1px;
}

.btn-coffee {
  width: 100%;
}

.badge {
  font-size: 80%;
}

.toast {
  border-radius: 2px;
  border: none;
  background: $primary;
  color: white;
  .toast-header {
    border: none;
    background: $primary;
    color: $secondary;
    button {
      color: $secondary;
    }
  }
}