@font-face {
  font-family: prompt-black;
  src: url(fonts/Prompt-Black.ttf);
}

@font-face {
  font-weight: normal;
  font-family: prompt;
  src: url(fonts/Prompt-Regular.ttf);
}

@font-face {
  font-weight: bold;
  font-family: prompt;
  src: url(fonts/Prompt-Bold.ttf);
}

@font-face {
  font-weight: bold;
  font-family: prompt-light;
  src: url(fonts/Prompt-Light.ttf);
}

@font-face {
  font-weight: bold;
  font-family: prompt-thin;
  src: url(fonts/Prompt-Thin.ttf);
}